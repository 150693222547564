import styles from './CustomPipeDesign.module.scss';

import { useContext, useState } from "react";
import { AppContext } from "../../AppContainer";
import { useDispatch, useSelector } from 'react-redux';
import { setActiveDraftProduct, setCustomPipeData, setDraftProductData } from '../../../redux/actions';
import { CustomPipeColorBlock } from '../custom-pipe-color/custom-pipe-color-block';
import { insertColors } from '../../../models/static-data/insertColors';
import { Circle } from '../../common/circle';
import { Slider } from '../../common/slider';
import { RootState } from '../../../redux/store';
import {kindPipe} from "../../../models/settingsPipe";

export const CustomPipeDesign = () => {
    const { setActiveSlider, isMobile, isIPad, chooseDesignPipe, activeTypePipe, setActiveTypePipe } = useContext(AppContext);
    const dispatch = useDispatch()

    const chooseSlider = (id: number, color?: string | undefined) => {
        dispatch(setDraftProductData({ slider: color }))
        setActiveSlider(id);
    };

    const { activeItem } = useSelector((state: RootState) => state.draftProduct)

    // const [activeColor, setActiveColor] = useState(0)

    const onChooseActiveColor = (color: string, ind: number, colorName: string) => {
        dispatch(setActiveDraftProduct(ind))
        dispatch(setDraftProductData({ insertColor: color, colorName: colorName }))
        dispatch(setCustomPipeData({ bottomColorIndex: ind }))
        chooseDesignPipe(-1)
    }


    return (
        <>
            {/* <div className={styles.insert_colors} style={{ display: 'flex' }}>
                {
                    insertColors.map((color, ind) => {
                        return <Circle
                            key={color.id}
                            isActive={ind === activeItem}
                            onClick={() => onChooseActiveColor(color.color, ind, color.name)}
                            background={color.color}
                            name={color.name}
                            id={color.id}
                            marginLeft={'20px'}

                        />
                    })
                }
            </div> */}

            <div className={styles.slider__wrapper} >
                <div className={styles.tabs}>
                    <button
                        className={`${styles.tab} ${activeTypePipe === kindPipe.Standard ? styles.active : ''}`}
                        onClick={() => setActiveTypePipe(kindPipe.Standard)}
                    >
                        Standard
                    </button>
                    <button
                        className={`${styles.tab} ${activeTypePipe === kindPipe.Mini ? styles.active : ''}`}
                        onClick={() => setActiveTypePipe(kindPipe.Mini)}
                    >
                        Mini
                    </button>
                </div>
                <Slider />
            </div>



            {/* style={{ "marginTop": "-150px" }} */}

        </>

    )
};



