import React from 'react'
import { designPipes } from '../../../models/static-data/design-pipes'
import { useContext } from 'react'
import { AppContext } from '../../AppContainer';
import { insertColors } from '../../../models/static-data/insertColors';
import styles from './Slider.module.scss';
import _ from 'lodash';
import { Circle } from '../circle';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveDraftProduct, setCustomPipeData, setDraftProductData } from '../../../redux/actions';
import { RootState } from '../../../redux/store';
import {kindPipe} from "../../../models/settingsPipe";


function SliderWrapper() {
    const { isMobile, activeDesignPipe, chooseDesignPipe } = useContext(AppContext)
    const countOfItems = isMobile ? 6 : 10;

    const dispatch = useDispatch()
    const { activeItem } = useSelector((state: RootState) => state.draftProduct)


    const onChooseDesignPipe = (e: any, image: string, imageName: string) => {
        dispatch(setDraftProductData({ colorName: "", }))
        dispatch(setActiveDraftProduct(null))
        chooseDesignPipe(e)
        dispatch(setDraftProductData({ image }))
        dispatch(setCustomPipeData({ insertImageIndex: imageName }))
    }

    return (
        <>
            {
                _.chunk(designPipes[kindPipe.Standard], countOfItems).map((slide, i) => {
                    return (
                        <div className={`keen-slider__slide ${styles.slide}`} key={i}>
                            {
                                slide.map((pipe, ind) => {
                                    return (
                                        <Circle
                                            key={pipe.id}
                                            isActive={activeDesignPipe === pipe.id}
                                            //@ts-ignore
                                            onClick={(e) => onChooseDesignPipe(e, pipe.image, pipe.imageName)}
                                            background={pipe.image}
                                            id={pipe.id}
                                        />
                                    )
                                })
                            }
                        </div>
                    )
                }
                )
            }
        </>
    )
}

export default SliderWrapper
