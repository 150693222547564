import {ColorPipe, DesignPipe} from "../settingsPipe";
import {
    australia,
    black_bottom,
    black_top,
    blackInsert,
    blue_bottom,
    blue_top,
    blueberry,
    // blueInsert,
    frivolousGenius,
    frivolousGeniusBlue,
    gold_bottom,
    gold_top,
    goldInsert, greenInsert,
    herb,
    highRollerCherry,
    highRollerMahogany,
    hypnoticGenius,
    independenceDay,
    legionOfSkanks,
    madHatter,
    mexico,
    miniPipe1,
    miniPipe2,
    miniPipe3,
    miniPipe4,
    miniPipe5,
    miniSlider1,
    monaLisa,
    nightOverExon,
    passion,
    passionSunset,
    pipes1,
    pipes2,
    pipes3,
    pipes4,
    pipes5,
    pipes6,
    purple_bottom,
    purple_top, purpleInsert,
    rainbowWave,
    red_bottom,
    red_top, redInsert,
    redQueen,
    roseSelavy,
    samsara,
    serpentAndSkulls,
    silver_bottom,
    silver_top,
    silverInsert,
    sliders1,
    slimStuntaSnake,
    steampunkAbe,
    stuntLifestyle,
    teal_bottom,
    teal_top,
    tealInsert,
    theGuardian,
    throughTheUniverse,
    useMeLikeADrug,
} from "../../assets/images";

const COLOR_URL = "https://geniuspipe.com/collections/genius-colors?_=pf&pf_opt_color=";
const BUY_URL = 'https://geniuspipe.com/cart/add?id='

export const colors = {
    standard: [{
        id: ColorPipe.Black,
        color: 'linear-gradient(180deg, #262221 0%, rgba(38, 34, 33, 0.42) 100%)',
        name: 'Black',
        pipe: pipes2,
        slider: sliders1,
        link: `${BUY_URL}44973644105`,
        numberId: 44973644105,
        nameColor: "black"

    }, {
        id: ColorPipe.Silver,
        color: 'linear-gradient(180deg, #817C7C 0%, rgba(192, 189, 189, 0.42) 100%)',
        name: 'Silver',
        pipe: pipes1,
        slider: sliders1,
        link: `${BUY_URL}25935192393`,
        numberId: 25935192393,
        nameColor: "silver"
    }, {
        id: ColorPipe.Gold,
        color: '#D7B759',
        name: 'Gold',
        pipe: pipes3,
        slider: sliders1,
        link: `${BUY_URL}27548933705`,
        numberId: 27548933705,
        nameColor: "gold"
    }, {
        id: ColorPipe.Purple,
        color: '#BB67E3',
        name: 'Purple',
        pipe: pipes4,
        slider: sliders1,
        link: `${BUY_URL}31264403529`,
        numberId: 31264403529,
        nameColor: "purple"
    }, {
        id: ColorPipe.Teal,
        color: '#15CDED',
        name: 'Teal',
        pipe: pipes4,
        slider: sliders1,
        link: `${BUY_URL}31264403529`,
        numberId: 31264403529,
        nameColor: "teal"
    }, {
        id: ColorPipe.Blue,
        color: '#2260ef',
        name: 'Blue',
        pipe: pipes5,
        slider: sliders1,
        link: `${BUY_URL}30577369161`,
        numberId: 30577369161,
        nameColor: "blue"
    }, {
        id: ColorPipe.Red,
        color: '#B40303',
        name: 'Red',
        pipe: pipes6,
        slider: sliders1,
        link: `${COLOR_URL}Red`,
        numberId: 30588212169,
        nameColor: "red"
    }],
    mini: [{
        id: ColorPipe.Silver,
        color: 'linear-gradient(180deg, #817C7C 0%, rgba(192, 189, 189, 0.42) 100%)',
        name: 'Silver',
        pipe: miniPipe1,
        slider: miniSlider1,
        link: `${BUY_URL}39304964571208`,
        numberId: 39304964571208,
        nameColor: "silver"

    }, {
        id: ColorPipe.Black,
        color: 'linear-gradient(180deg, #262221 0%, rgba(38, 34, 33, 0.42) 100%)',
        name: 'Black',
        pipe: miniPipe2,
        slider: miniSlider1,
        link: `${BUY_URL}39304964571208`,
        numberId: 39304964571208,
        nameColor: "silver"

    }, {
        id: ColorPipe.Gold,
        name: 'Gold',
        color: '#D7B759',
        pipe: miniPipe3,
        slider: miniSlider1,
        link: `${BUY_URL}39304964571208`,
        numberId: 39304964571208,
        nameColor: "silver"

    }, {
        id: ColorPipe.Teal,
        name: 'Teal',
        color: '#15CDED',
        pipe: miniPipe4,
        slider: miniSlider1,
        link: `${BUY_URL}39304964505672`,
        numberId: 39304964505672,
        nameColor: "silver"

    }, {
        id: ColorPipe.Red,
        color: '#B40303',
        name: 'Red',
        pipe: miniPipe5,
        slider: miniSlider1,
        link: `${BUY_URL}39304964538440`,
        numberId: 39304964538440,
        nameColor: "silver"

    }]
};

export const topColors = [
    {
        id: ColorPipe.Black,
        image: black_top,
        imageName: 'black'

    }, {
        id: ColorPipe.Silver,
        image: silver_top,
        imageName: 'silver'

    }, {
        id: ColorPipe.Gold,
        image: gold_top,
        imageName: 'gold'

    }, {
        id: ColorPipe.Purple,
        image: purple_top,
        imageName: 'purple'

    }, {
        id: ColorPipe.Teal,
        image: teal_top,
        imageName: 'teal'

    }, {
        id: ColorPipe.Blue,
        image: blue_top,
        imageName: 'blue'

    },
    {
        id: ColorPipe.Red,
        image: red_top,
        imageName: 'red'

    }
]

export const bottomColors = [
    {
        id: ColorPipe.Black,
        image: black_bottom,
        imageName: 'black'
    }, {
        id: ColorPipe.Silver,
        image: silver_bottom,
        imageName: 'silver'
    }, {
        id: ColorPipe.Gold,
        image: gold_bottom,
        imageName: 'gold'
    }, {
        id: ColorPipe.Purple,
        image: purple_bottom,
        imageName: 'purple'
    }, {
        id: ColorPipe.Teal,
        image: teal_bottom,
        imageName: 'teal'
    }, {
        id: ColorPipe.Blue,
        image: blue_bottom,
        imageName: 'blue'
    }, {
        id: ColorPipe.Red,
        image: red_bottom,
        imageName: 'red'
    },

]

export const insertImage = [
    {
        id: DesignPipe.Black,
        image: blackInsert,
        imageName: "black"
    }, {
        id: DesignPipe.Silver,
        image: silverInsert,
        imageName: "silver"
    }, {
        id: DesignPipe.Gold,
        image: goldInsert,
        imageName: "gold"
    }, {
        id: DesignPipe.Purple,
        image: purpleInsert,
        imageName: "purple"
    }, {
        id: DesignPipe.Teal,
        image: tealInsert,
        imageName: "teal"
    }, {
        id: DesignPipe.Green,
        image: greenInsert,
        imageName: "green"
    }, {
        id: DesignPipe.Red,
        image: redInsert,
        imageName: "red"
    },
    // {
    //     id: 4,
    //     image: blueInsert,
    //     imageName: 'blue'
    // },

    {
        id: 100,
        image: serpentAndSkulls,
        imageName: "serpentAndSkulls"
    },{
        id: 101,
        image: roseSelavy,
        imageName: "roseSelavy"
    },{
        id: 102,
        image: theGuardian,
        imageName: "theGuardian"
    },{
        id: 103,
        image: steampunkAbe,
        imageName: "steampunkAbe"
    },{
        id: 104,
        image: useMeLikeADrug,
        imageName: "useMeLikeADrug"
    },{
        id: 105,
        image: rainbowWave,
        imageName: "rainbowWave"
    },{
        id: DesignPipe.HypnoticGenius,
        image: hypnoticGenius,
        imageName: "hypnoticGenius"
    },{
        id: DesignPipe.LegionOfSkanks,
        image: legionOfSkanks,
        imageName: "legionOfSkanks"
    },{
        id: DesignPipe.NightOverExon,
        image: nightOverExon,
        imageName: "nightOverExon"
    },{
        id: DesignPipe.MonaLisa,
        image: monaLisa,
        imageName: "monaLisa"
    },{
        id: DesignPipe.FrivolousGenius,
        image: frivolousGenius,
        imageName: "frivolousGenius"
    },{
        id: DesignPipe.FrivolousGeniusBlue,
        image: frivolousGeniusBlue,
        imageName: "frivolousGeniusBlue"
    }, {
        id: DesignPipe.HighRollerMahogany,
        image: highRollerMahogany,
        imageName: "highRollerMahogany"
    }, {
        id: DesignPipe.HighRollerCherry,
        image: highRollerCherry,
        imageName: "highRollerCherry"
    }, {
        id: DesignPipe.IndependenceDay,
        image: independenceDay,
        imageName: "independenceDay"
    }, {
        id: DesignPipe.IndependenceDay,
        image: independenceDay,
        imageName: "independenceDay"
    }, {
        id: DesignPipe.Australia,
        image: australia,
        imageName: "australia"
    }, {
        id: DesignPipe.MadHatter,
        image: madHatter,
        imageName: "madHatter"
    }, {
        id: DesignPipe.RedQueen,
        image: redQueen,
        imageName: "redQueen"
    }, {
        id: DesignPipe.Passion,
        image: passion,
        imageName: "passion"
    }, {
        id: DesignPipe.PassionSunset,
        image: passionSunset,
        imageName: "passionSunset"
    }, {
        id: DesignPipe.Samsara,
        image: samsara,
        imageName: "samsara"
    }, {
        id: DesignPipe.ThroughTheUniverse,
        image: throughTheUniverse,
        imageName: "throughTheUniverse"
    }, {
        id: DesignPipe.Blueberry,
        image: blueberry,
        imageName: "blueberry"
    }, {
        id: DesignPipe.Herb,
        image: herb,
        imageName: "herb"
    }, {
        id: DesignPipe.Mexico,
        image: mexico,
        imageName: "mexico"
    }, {
        id: DesignPipe.StuntLifestyle,
        image: stuntLifestyle,
        imageName: "stuntLifestyle"
    }, {
        id: DesignPipe.SlimStuntaSnake,
        image: slimStuntaSnake,
        imageName: "slimStuntaSnake"
    },
]
