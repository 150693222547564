import React from 'react'
import styles from './Catalog.module.scss'
import { Header } from '../../components/header'
import { ShopTopProduct, CategoryComponent, Trending } from '../../components/page-components'
import { Footer } from '../../components/footer'

function Category() {
    return (
        <>
            <Header />
            <CategoryComponent />
            <ShopTopProduct />
            <Trending />
            <Footer />
        </>
    )
}

export default Category