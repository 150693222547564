import React, { useEffect, useState } from 'react'
import { Header } from '../../components/header'
import styles from './Catalog.module.scss'
import Client from 'shopify-buy';
import { createCheckout, fetchAllProducts, fetchNextProducts, fetchCollections, fetchQueryProductsF } from '../../utils/shop-utils'
import { useDispatch } from 'react-redux';


import { RangePrice } from './PriceRange';
import Filter from './Filter/Filter';
import PaginationBlock from './Pagination/PaginationBlock';
import { ProductLoader } from '../../components/page-components';

import CatalogProduct from './CatalogProduct/CatalogProduct';
import { catalogProduct, ratingWhiteStar, ratingBlackStar, ItemCircleBg } from '../../assets/images'
import { useSelector } from 'react-redux';
import { createCheckoutAC, setProducts, fetchProducts, setIsLoaded } from '../../redux/actions';
import axios from 'axios';

// 0, 1, 2
// 1, 2, 3

function Catalog() {
    const dispatch = useDispatch()

    const { minPrice, maxPrice, filteredProducts, isLoaded } = useSelector(state => state.catalog)

    const [pagination, setPagination] = useState({ activePage: 0, paginationMethod: 'scroll' })

    console.log({ minPrice, maxPrice })

    const priceFilteredProds = filteredProducts.filter(prod => prod.price <= maxPrice && prod.price >= minPrice)


    const maxPage = Math.ceil(priceFilteredProds.length / 20)
    console.log('maxPage catalog', maxPage)

    const getPaginatedProducts = () => {
        let paginateProducts = []
        if (pagination.paginationMethod === 'scroll') {
            paginateProducts = priceFilteredProds.slice(0, (pagination.activePage + 1) * 20)
        } else if (pagination.paginationMethod === 'page') {
            console.log('by page')
            const startIndex = pagination.activePage > 0 ? pagination.activePage * 20 : 0
            paginateProducts = priceFilteredProds.slice(startIndex, (pagination.activePage + 1) * 20)
        }
        return paginateProducts
    }


    useEffect(() => {
        setIsLoaded(false)
        axios.get('https://9emd7egfpf.execute-api.us-east-1.amazonaws.com/DEV').then(data => {
            // console.log(JSON.parse(data.data.body))
            console.log('products:', data.data.body[0])
            dispatch(setProducts(data.data.body))
            dispatch(setIsLoaded(true))
        })
        // fetchCollections()
    }, [])


    return (
        <>
            <Header heightToHideHeader={30} />
            <div style={{ paddingBottom: '121px' }}>
                <main>
                    <div className={`${styles.catalog__info} ${styles.inner}`}>
                        <div className={styles.catalog__text}>
                            <p className={styles.catalog__title}>Genius Pipes</p>
                            <p className={styles.catalog__about}>The Genius Collection of pipes come
                                in a variety of options including our line of Classic, Mini, Color, and Limited
                                designs. Genius Pipe has been providing quality products
                                in the United States and abroad since 2014 and has been featured in GQ Magazine,
                                The Daily Beast, High Times, Touch of Modern, Herb, and endorsed by many influencers in the world of music,
                                sports, business, and fashion.</p>
                        </div>
                        <img className={styles.catalog__img} src={catalogProduct} alt="image" />
                    </div>
                    <div className={`${styles.catalog__wrapper} ${styles.inner}`}>
                        <div className={styles.catalog__filter}>
                            <Filter setPagination={setPagination} />
                            <div className={styles.catalog__price}>
                                <span className={`${styles.filter__title}`}>price</span>
                                <RangePrice setPagination={setPagination} />
                                {/* <RangePrice /> */}
                            </div>
                            <div className={styles.catalog__colors}>
                                <span className={`${styles.filter__title}`}>colors</span>
                                <div className={styles.colors}>
                                    <div className={styles.color_wrap}>
                                        <div className={`${styles.color} ${styles.silver}`}></div>
                                        <p className={styles.color__name}>Silver</p>
                                    </div>
                                    <div className={styles.color_wrap}>
                                        <div className={`${styles.color} ${styles.black} ${styles.active}`}></div>
                                        <p className={styles.color__name}>Black</p>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className={styles.catalog__products}>
                            {
                                isLoaded ?
                                    getPaginatedProducts().map(({ tags, id, status, image, variants, title, price, options, productType, imageSrc }, ind) => {
                                        if (price <= maxPrice && price >= minPrice) {
                                            return <CatalogProduct
                                                dispatch={dispatch}
                                                styles={styles}
                                                key={`${id}${ind}`}
                                                imgSrc={imageSrc ? imageSrc : ''}
                                                price={price}
                                                title={title} />
                                        }
                                    })
                                    :
                                    Array(10).fill(0).map((_, ind) => <ProductLoader key={ind} />)
                            }
                        </div>
                    </div>
                </main>
                <PaginationBlock maxPage={maxPage} activePage={pagination.activePage} setPagination={setPagination} />
            </div>
        </>
    )
}

export default Catalog