
import axios from "axios"

// FixedCustomPipeReducer

export const fixedPipeAC = (payload: boolean) => {
    return { type: "changeVisibleFixedPipe", payload }
}

export const changeCustomValue1AC = (payload: any) => {
    return { type: "changeCustomValue1", payload }
}

export const changeCustomValue2AC = (payload: any) => {
    return { type: "changeCustomValue2", payload }
}

export const changeCountLinesAC = (payload: any) => {
    return { type: "cahngeCountLines", payload }
}

// customPipeReducer
export const changeActiveTabAC = (payload: number) => {
    return { type: "setActiveTab", payload }
}


// cart
export const setCartData = (payload: any) => {
    return { type: "setCartData", payload }
}
export const setVisibleCart = (payload: any) => {
    return { type: "setCartVisible", payload }
}



// shop
export const createCheckoutAC = (dispatch: any) => (payload: any) => {
    dispatch({ type: "setCheckout", payload })
}

export const setProducts = (payload: any) => {
    return { type: "setProducts", payload }
}

export const setFilteredProducts = (payload: any) => {
    return { type: "setFilteredProducts", payload }
}

export const setFilterBy = (payload: any) => {
    return { type: "setFilterBy", payload }
}

// catalog

export const filterProductsByPrice = (payload: any) => {
    return { type: "filterByPrice", payload: { minPrice: payload.minPrice, maxPrice: payload.maxPrice } }
}

export const setIsLoaded = (payload: any) => {
    return { type: "setIsLoaded", payload }
}


// about 
export const setAboutData = (payload: any) => {
    return { type: "setProductData", payload }
}

// draft
export const setDraftProductData = (payload: any) => {
    return { type: "setDraftProductData", payload }
}

export const setActiveDraftProduct = (payload: any) => {
    return { type: "setDraftActiveItem", payload }
}



// checkout

export const setVisibleCheckout = (payload: any) => {
    return { type: 'setIsVisibleCheckout', payload }
}

// custom pipe

export const setCustomPipeData = (payload: any) => {
    return { type: 'setCustomPipeData', payload }
}

export const setExistPipes = (payload: any) => {
    return { type: "setExistPipes", payload: { existProducts: payload } }
}

// test