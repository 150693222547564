import React, { FC } from 'react';
import { SettingsPipe } from "../../models/settingsPipe";
import { CustomPipeColor } from "./custom-pipe-color";
import { CustomPipeSlider } from "./custom-pipe-slider";
import { CustomPipeDesign } from "./custom-pipe-design";
import { CustomPipeEngraving } from "./custom-pipe-engraving";
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';


export const CustomPipeContent: FC = () => {
    const activeTab = useSelector((state: RootState) => state.CustomPipeExample.activeTab)
    switch (activeTab) {
        case SettingsPipe.Slider:
            return <CustomPipeSlider />;
        case SettingsPipe.Personalize:
            return <CustomPipeEngraving />;
        case SettingsPipe.Design:
            return <CustomPipeDesign />;
        default:
            return <CustomPipeColor />
    }
};

// test
