// import {menu, social} from "../../models/static-data/footer";
import React from 'react'
import styles from './Footer.module.scss';
import images from "../../assets/icons/logo-mob.svg"

function Footer() {
    return (
        <>
            <footer className={styles.footer}>
                <div className={styles.container}>
                    <div className={styles.footer__blocks}>
                        <div className={styles.footer__block}>
                            <a href="#" className={styles.logo}>
                                <img src={images} alt=""/>
                            </a>
                        </div>
                        <div className={styles.footer__block}>
                                <ul className={styles.menu}>
                                    <li><a className={styles.menu__link} href="#">Catalog</a></li>
                                    <li><a className={styles.menu__link} href="#">Privacy policy</a></li>
                                    <li><a className={styles.menu__link} href="#">Karma</a></li>
                                    <li><a className={styles.menu__link} href="#">Contacts</a></li>
                                    <li><a className={styles.menu__link} href="#">Legal</a></li>
                                    <li><a className={styles.menu__link} href="#">Press</a></li>
                                    <li><a className={styles.menu__link} href="#">FAQ</a></li>
                                    <li><a className={styles.menu__link} href="#">COVID-19</a></li>
                                </ul>
                        </div>
                        <div className={styles.footer__block}>
                            <div className={styles.footer__socials}>
                                <a className={styles.footer__socIcon} href="#">
                                    <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="15" cy="15" r="15" fill="#1B1817"/>
                                        <g clip-path="url(#clip0_725_1164)">
                                            <path d="M12.4823 25V15.6154H10V12.2365H12.4823V9.3505C12.4823 7.08264 13.9487 5 17.3274 5C18.6954 5 19.707 5.1311 19.707 5.1311L19.6273 8.28642C19.6273 8.28642 18.5956 8.27638 17.4699 8.27638C16.2514 8.27638 16.0562 8.83768 16.0562 9.7693V12.2365H19.7241L19.5645 15.6154H16.0562V25H12.4823Z" fill="white"/>
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_725_1164">
                                                <rect width="9.72414" height="20" fill="white" transform="translate(10 5)"/>
                                            </clipPath>
                                        </defs>
                                    </svg></a>
                                <a className={styles.footer__socIcon} href="#"><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="15" cy="15" r="15" fill="#1B1817"/>
                                    <path d="M24.5834 10.4224C24.4701 10.0184 24.2495 9.65269 23.945 9.36407C23.6319 9.06657 23.2482 8.85376 22.83 8.74573C21.265 8.33323 14.995 8.33323 14.995 8.33323C12.3811 8.30349 9.76788 8.4343 7.17003 8.7249C6.75185 8.84091 6.36883 9.05847 6.05503 9.35823C5.74669 9.6549 5.52336 10.0207 5.40669 10.4216C5.12644 11.9314 4.99027 13.4644 5.00003 14.9999C4.99003 16.5341 5.12586 18.0666 5.40669 19.5782C5.52086 19.9774 5.74336 20.3416 6.05253 20.6357C6.36169 20.9299 6.74669 21.1424 7.17003 21.2549C8.75586 21.6666 14.995 21.6666 14.995 21.6666C17.6122 21.6963 20.2288 21.5655 22.83 21.2749C23.2482 21.1669 23.6319 20.9541 23.945 20.6566C24.2494 20.368 24.4697 20.0022 24.5825 19.5982C24.8701 18.089 25.0099 16.5554 25 15.0191C25.0217 13.4762 24.882 11.9354 24.5834 10.4216V10.4224ZM13.0017 17.8532V12.1474L18.2184 15.0007L13.0017 17.8532Z" fill="white"/>
                                </svg></a>
                                <a className={styles.footer__socIcon} href="#"><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="15" cy="15" r="15" fill="#1B1817"/>
                                    <path d="M15.0001 12.3961C13.5665 12.3961 12.3966 13.566 12.3966 14.9996C12.3966 16.4332 13.5665 17.6031 15.0001 17.6031C16.4337 17.6031 17.6036 16.4332 17.6036 14.9996C17.6036 13.566 16.4337 12.3961 15.0001 12.3961ZM22.8087 14.9996C22.8087 13.9215 22.8185 12.8531 22.7579 11.7769C22.6974 10.5269 22.4122 9.41757 21.4981 8.50351C20.5821 7.58749 19.4747 7.30429 18.2247 7.24374C17.1466 7.1832 16.0782 7.19296 15.002 7.19296C13.9239 7.19296 12.8556 7.1832 11.7794 7.24374C10.5294 7.30429 9.42001 7.58945 8.50595 8.50351C7.58994 9.41952 7.30673 10.5269 7.24619 11.7769C7.18564 12.8551 7.19541 13.9234 7.19541 14.9996C7.19541 16.0758 7.18564 17.1461 7.24619 18.2223C7.30673 19.4723 7.59189 20.5816 8.50595 21.4957C9.42197 22.4117 10.5294 22.6949 11.7794 22.7555C12.8575 22.816 13.9259 22.8062 15.002 22.8062C16.0802 22.8062 17.1485 22.816 18.2247 22.7555C19.4747 22.6949 20.5841 22.4097 21.4981 21.4957C22.4142 20.5797 22.6974 19.4723 22.7579 18.2223C22.8204 17.1461 22.8087 16.0777 22.8087 14.9996ZM15.0001 19.0055C12.7833 19.0055 10.9942 17.2164 10.9942 14.9996C10.9942 12.7828 12.7833 10.9937 15.0001 10.9937C17.2169 10.9937 19.006 12.7828 19.006 14.9996C19.006 17.2164 17.2169 19.0055 15.0001 19.0055ZM19.17 11.7652C18.6524 11.7652 18.2345 11.3473 18.2345 10.8297C18.2345 10.3121 18.6524 9.89413 19.17 9.89413C19.6876 9.89413 20.1056 10.3121 20.1056 10.8297C20.1057 10.9526 20.0816 11.0743 20.0347 11.1879C19.9877 11.3015 19.9188 11.4047 19.8319 11.4916C19.745 11.5785 19.6418 11.6474 19.5282 11.6943C19.4146 11.7413 19.2929 11.7654 19.17 11.7652Z" fill="white"/>
                                </svg></a>
                                <a className={styles.footer__socIcon} href="#"><svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="15" cy="15" r="15" fill="#1B1817"/>
                                    <path d="M24.7024 9.11464C24.0066 9.42297 23.2591 9.6313 22.4733 9.72547C23.2841 9.2403 23.8907 8.47669 24.1799 7.57714C23.4181 8.02963 22.5844 8.34813 21.7149 8.5188C21.1302 7.89453 20.3558 7.48074 19.5119 7.3417C18.6679 7.20266 17.8017 7.34613 17.0476 7.74985C16.2936 8.15357 15.6939 8.79494 15.3417 9.57439C14.9895 10.3538 14.9045 11.2278 15.0999 12.0605C13.5563 11.983 12.0463 11.5818 10.6678 10.8829C9.28928 10.184 8.07313 9.20311 7.09826 8.0038C6.76493 8.5788 6.57326 9.24547 6.57326 9.95547C6.57289 10.5946 6.73029 11.224 7.03149 11.7877C7.3327 12.3515 7.7684 12.8322 8.29993 13.1871C7.68349 13.1675 7.08066 13.001 6.5416 12.7013V12.7513C6.54153 13.6478 6.85162 14.5166 7.41925 15.2105C7.98687 15.9043 8.77707 16.3804 9.65576 16.558C9.08391 16.7127 8.48438 16.7355 7.90243 16.6246C8.15034 17.396 8.63326 18.0705 9.28357 18.5537C9.93388 19.037 10.719 19.3048 11.5291 19.3196C10.154 20.3991 8.45567 20.9847 6.70743 20.9821C6.39775 20.9822 6.08833 20.9641 5.78076 20.928C7.55533 22.0689 9.62105 22.6745 11.7308 22.6721C18.8724 22.6721 22.7766 16.7571 22.7766 11.6271C22.7766 11.4605 22.7724 11.2921 22.7649 11.1255C23.5243 10.5763 24.1799 9.89622 24.7008 9.11714L24.7024 9.11464Z" fill="white"/>
                                </svg></a>
                            </div>
                            <span className={styles.footer__copyright}>© 2022, Genius One</span>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer

// export const Footer = () => {
//     const date = new Date().getFullYear();
//
//     return (
//         <footer className={styles.footer}>
//             <div className={styles.copyright}>
//                 <Logo width={120} height={45}/>
//             </div>
//
//             {menu.map((item, i) =>
//                 <div key={i} className={styles.block}>
//                     <ul>
//                         {
//                             item.map(({title, link, id}) =>
//                                 <li key={id}>
//                                     <a
//                                         href={link}
//                                         className={styles.link}
//                                         target="_blank"
//                                         rel="noreferrer"
//                                     >
//                                         {title}
//                                     </a>
//                                 </li>
//                             )
//                         }
//                     </ul>
//                 </div>
//             )}
//
//             <div className={`${styles.block} ${styles.socials}`}>
//                 {
//                     social.map(({id, icon, link, title}) =>
//                         <a
//                             href={link}
//                             key={id}
//                             className={styles.social}
//                             target="_blank"
//                             rel="noreferrer"
//                         >
//                             <img src={icon} alt={title}/>
//                         </a>
//
//                     )
//                 }
//                 <p>&copy; {date}, Genius One</p>
//             </div>
//         </footer>
//     )
// };