const initialState = {
    visibleCheckout: false
}

function checkoutReducer(state = initialState, action: any) {
    switch (action.type) {

        case 'setIsVisibleCheckout':
            return {
                ...state,
                visibleCheckout: action.payload
            }

        default:
            return state
    }
}

export default checkoutReducer