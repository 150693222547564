import { Button } from '../../common/button'
import { Logo } from '../../common/logo'
import styles from './HeaderScroll.module.scss'
import React, { FC, useContext } from 'react'
import { AppContext } from '../../AppContainer'
import { Links } from '../../../models/static-data/links'
import { HeaderMobile } from '../header-mobile'
import searchIcon from '../../../assets/icons/header/search.svg'
import { newCart} from "../../../assets/icons";

export const HeaderScroll: FC<{
	setIsOpen: React.Dispatch<React.SetStateAction<boolean>>,
}> = ({ setIsOpen }) => {
	const { isHeaderWhite, isIPad, isOpenMobileMenu, setIsOpenMobileMenu, setIsActiveSearchModal, isActiveSearchModal } =
		useContext(AppContext)

	const openMenu = () => {
		if (!isIPad) {
			setIsOpen(false)
			return
		}

		setIsOpenMobileMenu(true)
	}

	const toggleMenu = () => {
		if (!isIPad) {
			setIsOpen(false)
			return
		}

		setIsOpenMobileMenu(!isOpenMobileMenu)
	}

	return (
		<header className={`${styles.header} ${isHeaderWhite ? styles.white : ''}`}>
			<div className={styles.logo}>
				<Logo width={93} height={35} />
			</div>
			<div className={styles.controls}>
				<button type='button'  className={styles.search} onClick={() => setIsActiveSearchModal(!isActiveSearchModal)}>
						<img src={searchIcon} alt="search" />
				</button>
					<a
							href={''}
							className={styles.cart}
							target="_blank"
							rel="noreferrer"
						>
								<img src={newCart} alt={''} />
					</a>
				<button
					className={`${styles.toggle} ${isHeaderWhite ? styles.white : ''} ${isOpenMobileMenu ? styles.active : ''}`}
					onClick={toggleMenu}
				>
					<span></span><span></span><span></span>
				</button>
			</div>

			{isOpenMobileMenu && <HeaderMobile />}
		</header>
	)
}
