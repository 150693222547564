import React from 'react'
import styles from './Checkout.module.scss'
import { useDispatch } from 'react-redux'
import { setVisibleCart, setVisibleCheckout } from '../../redux/actions'

const Checkout = () => {
    const dispatch = useDispatch()

    const closeCheckout = () => {
        dispatch(setVisibleCheckout(false))
    }
    return (
        <>
            <div className={styles.сheckout}>
                <button type="button" onClick={closeCheckout} className={styles.сheckout__close}></button>
                <div className={"container"}>
                    <div className={styles.сheckout__head}>

                    </div>
                    <div className={styles.сheckout__row}>
                        <div className={styles.сheckout__col}>
                            <div className={styles.accordion}>
                                <div className={styles.accordion__item}>
                                    <div className={styles.accordion__head}>
                                        <h4 className={styles.accordion__title}>1.Email</h4>
                                    </div>
                                    <div className={styles.accordion__content}>
                                        <form action="#" className={styles.form}>
                                            <div className={styles.form__wrap}>
                                                <div className={styles.form__field}>
                                                    <input type="email" placeholder="Email" className={styles.form__input} />
                                                </div>
                                                <div className={styles.form__block}>
                                                    <a href="#" className={styles.form__link}>Already have an account?</a>
                                                </div>
                                                <div className={styles.form__footer}>
                                                    <button type="button" className={styles.form__btn}>next</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className={styles.accordion__item}>
                                    <div className={styles.accordion__head}>
                                        <h4 className={styles.accordion__title}>2.shipping information</h4>
                                    </div>
                                    <div className={styles.accordion__content}>
                                        <form action="#" className={styles.form}>
                                            <div className={styles.form__wrap}>
                                                <div className={styles.form__row}>
                                                    <div className={styles.form__col}>
                                                        <div className={styles.form__field}>
                                                            <input type="text" placeholder="First name" className={styles.form__input} />
                                                        </div>
                                                    </div>
                                                    <div className={styles.form__col}>
                                                        <div className={styles.form__field}>
                                                            <input type="text" placeholder="First name" className={styles.form__input} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.form__field}>
                                                    <input type="text" placeholder="First name" className={styles.form__input} />
                                                </div>
                                                <div className={styles.form__row}>
                                                    <div className={styles.form__col}>
                                                        <div className={styles.form__field}>
                                                            <input type="text" placeholder="country/region" className={styles.form__input} />
                                                        </div>
                                                    </div>
                                                    <div className={styles.form__col}>
                                                        <div className={styles.form__field}>
                                                            <input type="text" placeholder="address" className={styles.form__input} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.form__field}>
                                                    <input type="text" placeholder="apartment, suite, etc (optional)" className={styles.form__input} />
                                                </div>
                                                <div className={styles.form__row}>
                                                    <div className={styles.form__col}>
                                                        <div className={styles.form__field}>
                                                            <input type="text" placeholder="city" className={styles.form__input} />
                                                        </div>
                                                    </div>
                                                    <div className={styles.form__col}>
                                                        <div className={styles.form__field}>
                                                            <input type="text" placeholder="postal code" className={styles.form__input} />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.form__field}>
                                                    <input type="text" placeholder="phone (optional)" className={styles.form__input} />
                                                </div>
                                                <div className={styles.form__footer}>
                                                    <button type="button" className={styles.form__btn}>next</button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                                <div className={styles.accordion__item}>
                                    <div className={styles.accordion__head}>
                                        <h4 className={styles.accordion__title}>3.Shipping method</h4>
                                    </div>
                                    <div className={styles.accordion__content}>
                                    
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.сheckout__col}>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Checkout