const initialState = {
    totalCount: 0,
    totalPrice: 0,
    items: [],
    visibleCart: false
}

function cartReducer(state = initialState, action: any) {
    switch (action.type) {
        case "setCartData":
            return {
                ...state,
                ...action.payload
            }
        case "setCartVisible":
            return {
                ...state,
                visibleCart: action.payload
            }
        default:
            return state
    }
}

export default cartReducer