import React, { FC, MouseEvent, useContext } from "react";
import styles from './Slider.module.scss';
import { useKeenSlider } from "keen-slider/react";
import { ArrowRight } from "./ArrowRight";
import { ArrowLeft } from "./ArrowLeft";
import "keen-slider/keen-slider.min.css"
import { designPipes } from "../../../models/static-data/design-pipes";
import { Circle } from "../circle";
import { AppContext } from "../../AppContainer";
import _ from 'lodash';
import { useDispatch, useSelector } from "react-redux";
import { setActiveDraftProduct, setCustomPipeData, setDraftProductData } from "../../../redux/actions";
import { insertColors } from "../../../models/static-data/insertColors";
import { RootState } from "../../../redux/store";
import SliderWrapper from './SliderWrapper'



export const Slider: FC = () => {
    const { isMobile, activeDesignPipe, chooseDesignPipe } = useContext(AppContext);
    const [sliderRef, slider] = useKeenSlider<HTMLDivElement>({
        initial: 0,
        loop: true
    });

    const dispatch = useDispatch()
    const { activeItem } = useSelector((state: RootState) => state.draftProduct)

    const countOfItems = isMobile ? 5 : 8;

    const onChooseDesignPipe = (e: any, image: string, ind: number) => {
        dispatch(setDraftProductData({ colorName: "", }))
        dispatch(setActiveDraftProduct(null))
        chooseDesignPipe(e)
        dispatch(setDraftProductData({ image }))
        dispatch(setCustomPipeData({ insertImageIndex: ind }))
    }

    const onChooseActiveColor = (color: string, ind: number, colorName: string) => {
        dispatch(setActiveDraftProduct(ind))
        dispatch(setDraftProductData({ insertColor: color, colorName: colorName }))
        dispatch(setCustomPipeData({ bottomColorIndex: ind }))
        chooseDesignPipe(-1)
    }

    return (
        <div className={styles.slider}>
            <div ref={sliderRef} className="keen-slider">
                <SliderWrapper />
            </div>
            {slider && (
                <>
                    {/* @ts-ignore*/}
                    <ArrowLeft onClick={(e: MouseEvent<HTMLElement>) => e.stopPropagation() || slider.prev()}
                    />
                    {/*@ts-ignore*/}
                    <ArrowRight onClick={(e: MouseEvent<HTMLElement>) => e.stopPropagation() || slider.next()}
                    />
                </>
            )}
        </div>
    )
};
