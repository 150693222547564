import React, { useEffect } from 'react';
import { Home, FixedCustomPipe, AboutPipe, Catalog, Shop, ProductSlider, Category } from '../pages'
import { Route, Routes } from 'react-router-dom'
import { useSelector } from 'react-redux';
import { RootState } from '../redux/store';
import { Cart } from './cart';
import { Checkout } from './checkout';
import axios from 'axios';

function App() {

    const { visibleCart } = useSelector((state: RootState) => state.cart)
    const { visibleCheckout } = useSelector((state: RootState) => state.checkout)
    // const getExistData = async () => {
    //     await axios.get('https://adwensae24.execute-api.us-east-1.amazonaws.com/prode/get-exist-goods').then(data => console.log(data))
    // }
    // useEffect(() => {
    //     getExistData()
    // })

    return (
        <>
            {visibleCart && <Cart />}
            {visibleCheckout && <Checkout />}

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/create-pipe-title" element={<FixedCustomPipe />} />
            </Routes>
        </>
    )
}

export default App; 