import axios from 'axios'
import React from 'react'

interface CartItemProps {
    styles: any,
    title: string,
    image: string,
    price: number,
    quantity: number,
    totalPrice: number,
    ind: number
}


function CartItem({ styles, image, price, quantity, totalPrice, ind }: CartItemProps) {

    const onPlusOneItem = () => {

    }
    const onMinusOneItem = () => {

    }

    console.log(
        {
            line: ind + 1,
            quantity,
            quantityPlus: quantity + 1,
            quantityMinus: quantity - 1,
        }
    )

    return (
        <>
            <div className={styles.cart__item}>
                <div className={`${styles.container}`}>
                    <img src={image} alt="product" className={styles.item__image} />
                    <span className={styles.item__title}>Genius Mini - Silver</span>
                    <span className={styles.item__price}>{price / 10}$</span>
                    <div className={styles.count_wrapper}>
                        <a className={styles.count__minus} href={`https://geniuspipe.com/cart/change?line=${ind + 1}&quantity=${quantity - 1}`}>-</a>
                        <span className={styles.count}>{quantity}</span>
                        <a className={styles.count__plus} href={`https://geniuspipe.com/cart/change?line=${ind + 1}&quantity=${quantity + 1}`}>+</a>
                    </div>
                    <span className={styles.item__total_price}>{totalPrice / 10}$</span>
                    <a href={`https://geniuspipe.com/cart/change?line=${ind + 1}&quantity=0`} className={`${styles.cart__cancel} ${styles.item__cancel}`}></a>
                </div>
            </div>
        </>
    )
}

export default CartItem