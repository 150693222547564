// @ts-nocheck

import { ProductSlider } from "../../pages"

const initialState = {
    products: [],
    isLoaded: false,
    minPrice: 0,
    maxPrice: 250,
    filteredProducts: [],
    filterValues: [],
}

function catalogReducer(state = initialState, action: any) {
    switch (action.type) {
        case "setCatalogData":
            return {
                ...state,
                products: action.products
            }
        case "setProducts":
            let pipes = []
            let otherProducts = []

            for (let product of action.payload) {

                otherProducts.push(product)
            }

            return {
                ...state,
                products: [...pipes, ...otherProducts],
                filteredProducts: [...pipes, ...otherProducts]
            }
        case "setFilteredProducts":
            return {
                ...state,
                filteredProducts: action.payload
            }
        case "setFilterBy": {

            let newFilteredProducts = []

            let newFilterValues = []

            if (state.filterValues.includes(action.payload)) {
                let tempFilterValues = [...state.filterValues]
                let indexPayloadValue = tempFilterValues.indexOf(action.payload)
                if (indexPayloadValue !== -1) {
                    tempFilterValues.splice(indexPayloadValue, 1)
                }
                newFilterValues = tempFilterValues


                if (newFilterValues.length === 0) {
                    newFilteredProducts = state.products
                } else {
                    for (let product of state.products) {
                        for (let filterKey of newFilterValues) {
                            if (product.tags.includes(filterKey)) newFilteredProducts.push(product)
                        }
                    }
                }
            } else {

                newFilterValues = [...state.filterValues, action.payload]

                for (let product of state.products) {
                    for (let filterKey of newFilterValues) {
                        if (product.tags.includes(filterKey)) newFilteredProducts.push(product)
                    }
                }


            }

            return {
                ...state,
                filteredProducts: newFilteredProducts,
                filterValues: newFilterValues
            }

        }

        case "filterByPrice":
            return {
                ...state,
                maxPrice: action.payload.maxPrice,
                minPrice: action.payload.minPrice
            }

        case "setIsLoaded":
            return {
                ...state,
                isLoaded: action.payload
            }
        default:
            return state
    }
}

export default catalogReducer