import {useContext} from 'react';
import styles from './CustomPipeColorBlock.module.scss';
import {AppContext} from "../../../AppContainer";
import {colors} from '../../../../models/static-data/colors';
import {Circle} from "../../../common/circle";
import {kindPipe} from "../../../../models/settingsPipe";
import {useDispatch} from 'react-redux';
import {setActiveDraftProduct, setCustomPipeData, setDraftProductData} from '../../../../redux/actions';
import {designPipes} from "../../../../models/static-data/design-pipes";
import {sliders} from "../../../../models/static-data/sliders";

export const CustomPipeColorBlock = (args: any) => {
    const { activeTypePipe, activeColorPipe, setActiveColorPipe,
        activeColorPipeMini, setActiveColorPipeMini, activeSlider, activeSliderMini, setActiveSlider, setActiveSliderMini  } = useContext(AppContext);
    const dispatch = useDispatch()

    let type = '', data
    switch (args?.type) {
        case 'mini':
            type = 'mini'
            data = designPipes.mini
            break;
        case 'slider':
            type = 'sliders'
            data = sliders[activeTypePipe]
            break;
        default:
            type = ''
            data = colors[activeTypePipe]
    }
    // const data = (args?.type === 'mini') ? designPipes.mini : (args?.type === 'slider') ? sliders[activeTypePipe] : colors[activeTypePipe]

    const setActivePipeColor = (e: any, color: string, topColor: string, bottomColor: string,) => {
        setActiveColorPipe(e)
        console.log()
        dispatch(setDraftProductData({ baseColor: color }))
        dispatch(setCustomPipeData({ bottomColorIndex: bottomColor, topColorIndex: topColor }))
        // dispatch(setCustomPipeData({ topColorIndex: ind }))
    }

    const chooseSlider = (e: any, id: number, color?: string | undefined) => {
        dispatch(setDraftProductData({ slider: color, colorName: "", }))
        dispatch(setActiveDraftProduct(null))
        dispatch(setCustomPipeData({ "sliderIndex": color }))

        if (activeTypePipe === kindPipe.Standard) {
            setActiveSlider(id)
        } else {
            setActiveSliderMini(id)
        }
    };


    return (
        <div className={`${styles.color} ${styles.max}`}>
            {data.map((color, ind) => {
                if (type !== 'sliders') {
                    return (
                        <Circle
                            key={color.id}
                            isActive={activeTypePipe === kindPipe.Standard ? activeColorPipe === color.id : activeColorPipeMini === color.id}
                            onClick={activeTypePipe === kindPipe.Standard ? (e) => setActivePipeColor(e, color.name, color.nameColor, color.nameColor) : setActiveColorPipeMini}
                            background={color.color}
                            name={color.name}
                            id={color.id}
                        />
                    )
                }

                return (
                    <Circle
                        key={color.id}
                        isActive={activeTypePipe === kindPipe.Standard ? activeSlider === color.id : activeSliderMini === color.id}
                        onClick={activeTypePipe === kindPipe.Standard ? (e) => chooseSlider(e, color.id) : setActiveSliderMini}
                        background={color.color}
                        name={color.name}
                        id={color.id}
                    />
                )
            })}
        </div>
    )
};
