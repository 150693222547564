import {
    miniSliders1,
    miniSliders3,
    miniSliders4,
    miniSliders5,
    miniSliders6,
    miniSliders7,
    sliderBlackEvolution,
    sliderBlackEvolutionGrinderPick,
    sliderGoldEvolution, sliderGoldEvolutionGrinderPick,
    sliderMiniBlack,
    sliderMiniSilver,
    sliders1,
    sliders2,
    sliders3,
    sliders7,
    sliders8,
    sliders9,
    sliderSilverEvolution, sliderSilverEvolutionGrinder, sliderSilverEvolutionGrinderPick
} from "../../assets/images";

import { Sliders } from "../settingsPipe";

const BUY_URL = 'https://geniuspipe.com/cart/add?id='

export const sliders = {
    standard: [{
        id: Sliders.BlackEvolution,
        image: `url(${sliderBlackEvolution})`,
        icon: sliderBlackEvolution,
        slider: sliderBlackEvolution,
        link: `${BUY_URL}43605377225`,
        number: 43605377225,
        color: `url(${sliderBlackEvolution}) no-repeat center center / contain`,
        name: '',
        nameColor: 'sliderBlackEvolution',
    }, {
        id: Sliders.SilverEvolution,
        image: `url(${sliderSilverEvolution})`,
        icon: sliderSilverEvolution,
        slider: sliderSilverEvolution,
        link: `${BUY_URL}43605377225`,
        number: 43605377225,
        color: `url(${sliderSilverEvolution}) no-repeat center center / contain`,
        name: '',
        nameColor: 'sliderMiniSilver',
    }, {
        id: Sliders.GoldEvolution,
        image: `url(${sliderGoldEvolution})`,
        icon: sliderGoldEvolution,
        slider: sliderGoldEvolution,
        link: `${BUY_URL}43605377225`,
        number: 43605377225,
        color: `url(${sliderGoldEvolution}) no-repeat center center / contain`,
        name: '',
        nameColor: 'sliderGoldEvolution',
    }, {
        id: Sliders.BlackEvolutionGrinderPick,
        image: `url(${sliderBlackEvolutionGrinderPick})`,
        icon: sliderBlackEvolutionGrinderPick,
        slider: sliderBlackEvolutionGrinderPick,
        link: `${BUY_URL}43605377225`,
        number: 43605377225,
        color: `url(${sliderBlackEvolutionGrinderPick}) no-repeat center center / contain`,
        name: '',
        nameColor: 'sliderBlackEvolutionGrinderPick',
    }, {
        id: Sliders.SilverEvolutionGrinderPick,
        image: `url(${sliderSilverEvolutionGrinderPick})`,
        icon: sliderSilverEvolutionGrinderPick,
        slider: sliderSilverEvolutionGrinderPick,
        link: `${BUY_URL}43605377225`,
        number: 43605377225,
        color: `url(${sliderSilverEvolutionGrinderPick}) no-repeat center center / contain`,
        name: '',
        nameColor: 'sliderSilverEvolutionGrinder',
    }, {
        id: Sliders.GoldEvolutionGrinderPick,
        image: `url(${sliderGoldEvolutionGrinderPick})`,
        icon: sliderGoldEvolutionGrinderPick,
        slider: sliderGoldEvolutionGrinderPick,
        link: `${BUY_URL}43605377225`,
        number: 43605377225,
        color: `url(${sliderGoldEvolutionGrinderPick}) no-repeat center center / contain`,
        name: '',
        nameColor: 'sliderGoldEvolutionGrinderPick',
    }
    // {
    //     id: 5,
    //     icon: miniSliders5,
    //     slider: sliders9,
    //     link: 'https://geniuspipe.com/collections/accessories/products/water-free-dab-rig-upgrade'
    // },{
    //     id: 6,
    //     icon: miniSliders6,
    //     slider: sliders8,
    //     link: 'https://geniuspipe.com/collections/accessories/products/water-free-dab-rig-upgrade'
    // },{
    //     id: 7,
    //     icon: miniSliders7,
    //     slider: sliders7,
    //     link: 'https://geniuspipe.com/collections/accessories/products/water-free-dab-rig-upgrade'
    // },
],
    mini: [{
        id: Sliders.BlackMini,
        image: `url(${sliderMiniBlack})`,
        icon: sliderMiniBlack,
        slider: sliderMiniBlack,
        link: `${BUY_URL}43605377225`,
        number: 43605377225,
        color: `url(${sliderMiniBlack}) no-repeat center center / contain`,
        name: '',
        nameColor: 'sliderMiniBlack',
    }, {
        id: Sliders.SilverMini,
        image: `url(${sliderMiniSilver})`,
        icon: sliderMiniSilver,
        slider: sliderMiniSilver,
        link: `${BUY_URL}43605377225`,
        number: 43605377225,
        color: `url(${sliderMiniSilver}) no-repeat center center / contain`,
        name: '',
        nameColor: 'sliderMiniSilver',
    }]
};
