import React, { FC } from "react";
import styles from './Title.module.scss';

export const Title: FC<{ className?: string, children: React.ReactNode }> = ({ children, className = '' }) => {
    return (
        <p className={`${styles.title} ${className}`}>{children}</p>
    )
};



