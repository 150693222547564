import styles from './HeaderMobile.module.scss'
import React, { useContext } from 'react'
import { leftNavigation, nav } from '../../../models/static-data/static-data'
import { Navigation } from '../../../models/navigation'
import { AppContext } from '../../AppContainer'
import { Close } from '../../common/close'
import { like, twitter, youtube, instagram, facebook } from "../../../assets/icons";
import {Button} from '../../common/button'

const socialList = [
	{
		id: '1',
		icon: facebook,
		link:'',
	},
	{
		id: '2',
		icon: youtube,
		link:'',
	},
	{
		id: '3',
		icon: instagram,
		link:'',
	},
	{
		id: '4',
		icon: twitter,
		link:'',
	},
	
]

export const HeaderMobile = () => {
	const { setIsOpenMobileMenu } = useContext(AppContext)

	const closeMenu = (
		e:
			| React.MouseEvent<HTMLDivElement, MouseEvent>
			| React.TouchEvent<HTMLDivElement>
	) => {
		if (e.currentTarget === e.target) {
			setIsOpenMobileMenu(false)
		}
	}

	return (
		<div
			className={styles.wrapper}
			onClick={closeMenu}
			onTouchStart={closeMenu}
		>
			<div className={styles.header}>
				<div className={styles.menu_inner}>
					<div className={styles.nav_wrap}>
						<ul className={styles.nav}>
							{nav.map(({ link, id, title }: Navigation, i) => (
								<li key={id}>
									<a
										href={link}
										className={styles.link}
										target="_blank"
										rel="noreferrer"
									>
										{title}
									</a>
									{i === nav.length - 1 ? (
										<button className={styles.like} type="button">
											<img src={like}/>
										</button>
									) : (
										''
									)}
								</li>
							))}
						</ul>
					</div>
					<ul className={styles.social}>
						{socialList.map(({ link, id, icon }) => (
							<li key={id} className={styles.social__item}>
								<a
									href={link}
									className={styles.social__link}
									target="_blank"
									rel="noreferrer"
								>
									<img src={icon} alt={''} />
								</a>
							</li>
						))}
					</ul>
				</div>
				<Button text='Login' mode='transparent_light' className={styles.login} />
			</div>
		</div>
	)
}

/* export const HeaderMobile = () => {
	const { setIsOpenMobileMenu } = useContext(AppContext)

	const closeMenu = (
		e:
			| React.MouseEvent<HTMLDivElement, MouseEvent>
			| React.TouchEvent<HTMLDivElement>
	) => {
		if (e.currentTarget === e.target) {
			setIsOpenMobileMenu(false)
		}
	}

	return (
		<div
			className={styles.wrapper}
			onClick={closeMenu}
			onTouchStart={closeMenu}
		>
			<div className={styles.header}>
				 <Close
					onClick={() => setIsOpenMobileMenu(false)}
					className={styles.close}
				/> 

				<div className={styles.nav_wrap}>
					<ul className={styles.nav}>
						{nav.map(({ link, id, title }: Navigation) => (
							<li key={id}>
								<a
									href={link}
									className={styles.link}
									target="_blank"
									rel="noreferrer"
								>
									{title}
								</a>
							</li>
						))}
					</ul>
				</div>
				<ul className={styles.menu}>
					{leftNavigation.map(({ link, id, icon, title }) => (
						<li key={id}>
							<a
								href={link}
								className={styles.link}
								target="_blank"
								rel="noreferrer"
							>
								<img src={icon} alt={title} />
								{title}
							</a>
						</li>
					))}
				</ul>
			</div>
		</div>
	)
}
 */