import Client from 'shopify-buy';
import { createCheckoutAC, setFilteredProducts, setProducts } from '../redux/actions';
import { store } from '../redux';
import axios from 'axios';

console.log(store)

const dispatch = store.dispatch

const client = Client.buildClient({
    domain: 'geniuspipes.myshopify.com',
    storefrontAccessToken: '9e9935d7e1969880d48116185e5f5b02'
});

export const createCheckout = async () => {
    const checkout = await client.checkout.create();
    localStorage.setItem("checkout", checkout.id);
    dispatch({ type: 'setCheckout', payload: checkout })
};

export const fetchCheckout = async (checkoutId) => {
    client.checkout
        .fetch(checkoutId)
        .then((checkout) => {
            console.log({ checkout: checkout });
        })
        .catch((err) => console.log(err));
};

export const fetchAllProducts = async () => {
    // returns only 20 products



    // ---------------------------------------------------------------------------------------

    // const products = await client.product.fetchAll(20);
    // console.log(axios)
    // const products = await axios.get('https://7986537ff03e42c88f06142974b10192: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@geniuspipes.myshopify.com//admin/api/2022-01/products.json',
    //     {
    //         'Content-Type': 'application/json',
    //         'X-Shopify-Access-Token': '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'
    //     }).then(data => data.data.products)
    // console.log(products)
    // dispatch({ type: 'setProducts', payload: products });
    // ---------------------------------------------------------------------------------------



    function getCartData(data) {
        console.log('data utils : ', data)

    }

    // // @ts-ignore
    // window.getCartData = getCartData

    // function test() {
    //     let script = document.createElement('script');
    //     script.src = 'https://7986537ff03e42c88f06142974b10192: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@geniuspipes.myshopify.com//admin/api/2022-01/products.json?callback=getCartData'
    //     document.getElementsByTagName('head')[0].appendChild(script);
    // }

    // test()
};


// export function fetchAllProducts() {
//     return new Promise((resolve, reject) => {
//         client.product.fetchAll()
//             .then((products) => {
//                 resolve(products.map((product) => {
//                     return product.attrs;
//                 }));
//             }).then(data => console.log('dddd', data)).catch((error) => {
//                 console.error(new Error('Fetching products error!'));
//                 reject(error);
//             });
//     });
// }

export const fetchNextProducts = async (products) => {
    client.fetchNextPage(products).then((nextProducts) => {
        console.log('nextProducts:', nextProducts)
    });
}

export const fetchCollections = async (products) => {

    // client.collection.fetchQuery({ first: 30 }).then((collections) => {
    // });

    client.collection.fetchAllWithProducts(30).then((collections) => {
        // Do something with the collections
        console.log(collections)
        // dispatch(setProducts(collections))
        // dispatch(setFilteredProducts(collections))
        // collection id : Z2lkOi8vc2hvcGlmeS9Db2xsZWN0aW9uLzMyMDcxMzgwMQ==
    });
}

export const addItemToCheckout = async (variantId, quantity) => {
    const lineItemsToAdd = [
        {
            variantId,
            quantity: parseInt(quantity, 10),
        },
    ];
    const checkout = await client.checkout.addLineItems(
        this.state.checkout.id,
        lineItemsToAdd
    );
    this.setState({ checkout: checkout });
    console.log(checkout);

    this.openCart();
};




export const fetchProductWithId = async (id) => {
    const product = await client.product.fetch(id);
    this.setState({ product: product });
    console.log(JSON.stringify(product));

    return product;
};