export enum SettingsPipe {
    Color = 1,
    Slider = 2,
    Design = 3,
    Personalize = 4
}

export enum ColorPipe {
    Black = 1,
    Silver,
    Gold,
    Purple,
    Teal,
    Blue,
    Red,
}

export enum kindPipe {
    Standard = 'standard',
    Mini = 'mini'
}

export enum DesignPipe {
    Black = 1,
    Silver,
    Gold,
    Purple,
    Teal,
    Green,
    Red,

    SerpentAndSkulls = 100,
    RoseSelavy,
    TheGuardian,
    SteampunkAbe,
    UseMeLikeADrug,
    RainbowWave,
    HypnoticGenius,
    LegionOfSkanks,
    NightOverExon,
    MonaLisa,
    FrivolousGenius,
    FrivolousGeniusBlue,
    HighRollerMahogany,
    HighRollerCherry,
    IndependenceDay,
    Australia,
    MadHatter,
    RedQueen,
    Passion,
    PassionSunset,
    Samsara,
    ThroughTheUniverse,
    Blueberry,
    Herb,
    Mexico,
    StuntLifestyle,
    SlimStuntaSnake,

    BoredApe = 1000,
    JungleBirds,
    Mahogany

    /*
        Cockpaw,
    Frivolous,
    Holiday,
    Candy,
    Hanukkah,
    Herb,
    Kwanzaa,
    Mexico,
    Cats,
    Roger,
    Wolf,
    Roller,
    RollerCherry,
    Hypnotic,
    Independence,
    Liberty,
    Mad,
    MonaLisa,
    Night,
    Passion,
    Psychedelic,
    Rainbow,
    Queen,
    Samsara,
    Portrait,
    StarryNight,
    Stunt,
    Gold,
    Black,
    Silver,
    Blue
     */
}

export enum Sliders {
    Black = 3001,
    BlackEvolution,
    BlackEvolutionGrinder,
    BlackEvolutionGrinderPick,
    Silver = 3011,
    SilverEvolution,
    SilverEvolutionGrinder,
    SilverEvolutionGrinderPick,
    GoldSilver = 3021,
    GoldEvolution,
    GoldEvolutionGrinder,
    GoldEvolutionGrinderPick,

    BlackMini = 3500,
    SilverMini,
    GOldMini
}

export enum SliderOptions {
    evolution = 3101,
    grinder,
    pick,
}
