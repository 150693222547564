import React, { FC, useContext } from 'react';
import { kindPipe, SettingsPipe } from "../../models/settingsPipe";
import styles from "./CustomPipe.module.scss";
import { Button } from "../common/button";
import { colors } from "../../models/static-data/colors";
import { designPipes } from "../../models/static-data/design-pipes";
import { AppContext } from "../AppContainer";
import { Links } from '../../models/static-data/links';
import { sliders } from "../../models/static-data/sliders";
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

export const CustomPipeAction: FC = () => {
    const {
        activeTypePipe,
        activeColorPipe,
        activeDesignPipe,
        activeSlider
    } = useContext(AppContext);

    const activeTab = useSelector((state: RootState) => state.CustomPipeExample.activeTab)
    const { topColorIndex, bottomColorIndex, insertImageIndex, sliderIndex, existProducts } = useSelector((state: RootState) => state.customPipe)
    const found = existProducts.find((product: any) => bottomColorIndex === product.bottomColor && product.topColor === topColorIndex && product.insertImage === insertImageIndex && product.slider === sliderIndex)


    const generateLink = () => {
        const colorPipeNumberId = colors[activeTypePipe].find((color: any) => color.id === activeColorPipe)?.numberId
        const sliderNumberId = sliders.standard.find(slider => slider.id === activeSlider)?.number

        console.log({ colorPipeNumberId, sliderNumberId })
        let link = ''

        if (found) {
            link = `https://geniuspipe.com/cart/add?id[]=${found.id}`
        } else {
            link = ''
        }

        return link
        // return sliderNumberId ? `https://geniuspipe.com/cart/add?id[]=${colorPipeNumberId}&quantity=1&id[]=${sliderNumberId}&quantity=1` : `https://geniuspipe.com/cart/add?id[]=${colorPipeNumberId}&quantity=1`
    }


    const calculatePrice = () => {

        if (found) {
            return found.price
        }

        if (found === undefined && sliderIndex == 'none') {
            return 99
        }

        return 129
    }

    switch (activeTab) {
        // case SettingsPipe.Slider:
        //     return (
        //         <div className={styles.action}>
        //             <p className={styles.price}>from $119.95</p>
        //             <Button
        //                 text={'Buy now'}
        //                 type={'link'}
        //                 link={designPipes.find(pipe => pipe.id === activeDesignPipe)?.link || Links.Shop}
        //             />
        //         </div>
        //     );
        // case SettingsPipe.Personalize:
        //     return (
        //         <div className={styles.action}>
        //             <p className={styles.price}>from $99.95</p>
        //             <Button text={'Buy now'} disabled={true} />
        //         </div>
        //     );
        // case SettingsPipe.Design:
        //     return (
        //         <div className={styles.action}>
        //             <p className={styles.price}>from 24.95</p>
        //             <Button
        //                 text={'Buy now'}
        //                 type={'link'}
        //                 link={generateLink()}
        //             />
        //         </div>
        //     );
        default:
            return (
                <div className={styles.action}>
                    <p className={styles.price}>
                        from ${calculatePrice()}
                    </p>
                    <Button
                        text={'Coming soon'}
                        // type={'link'}
                        // link={generateLink()}
                    />
                </div>
            )
    }
};
