import { Slider } from "../../common/slider";
import { Link } from "../../common/link";
import styles from './CustomPipeSlider.module.scss';
import { useContext } from "react";
import { AppContext } from "../../AppContainer";
import { sliders } from '../../../models/static-data/sliders';
import { setActiveDraftProduct, setCustomPipeData, setDraftProductData } from "../../../redux/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";

export const CustomPipeSlider = () => {
    const { openModal } = useContext(AppContext);


    const { setActiveSlider, isMobile, isIPad } = useContext(AppContext);
    const dispatch = useDispatch()
    const { activeItem } = useSelector((state: RootState) => state.draftProduct)

    const chooseSlider = (id: number, color?: string | undefined) => {
        dispatch(setDraftProductData({ slider: color, colorName: "", }))
        dispatch(setActiveDraftProduct(null))
        dispatch(setCustomPipeData({ "sliderIndex": color }))
        setActiveSlider(id);
    };

    return (
        <div>
            <div className={styles.slider}>
                {
                    sliders.standard.map(slide => (
                        <button key={slide.id} className={styles.item} onClick={() => chooseSlider(slide.id, slide.color)}>
                            <img src={slide.icon} alt="" />
                        </button>
                    ))
                }
                {/*<button style={{ color: '#fff', marginTop: "-150px !important" }} className={styles.item} onClick={() => chooseSlider(-1, "none")}>*/}
                {/*    None*/}
                {/*</button>*/}
            </div>

        </div>
    )
};
