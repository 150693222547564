import React, { useEffect } from 'react'
import styles from './Cart.module.scss'
import CartItem from './CartItem'
import OfferItem from './OfferItem'
import { Link } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { setCartData, setVisibleCart } from '../../redux/actions'

function Cart() {

    const { items, totalPrice } = useSelector((state: RootState) => state.cart)
    const dispatch = useDispatch()


    useEffect(() => {
        document.body.style.overflowY = 'hidden'
        function getCartData(data: any) {
            dispatch(setCartData({ totalCount: data.item_count, totalPrice: data.total_price, items: data.items }))

        }

        // @ts-ignore
        window.getCartData = getCartData

        function test() {
            let script = document.createElement('script');
            script.src = 'https://geniuspipe.com/cart.json?callback=getCartData'
            document.getElementsByTagName('head')[0].appendChild(script);
        }

        test()

        return () => {
            document.body.style.overflowY = 'auto'
        }
    }, [])

    const onCloseCartModal = () => {
        dispatch(setVisibleCart(false))
    }


    return (
        <div className={styles.cart_wrapper}>
            <div className={styles.cart}>
                <Link to="/">
                    <button className={styles.cart__cancel} onClick={onCloseCartModal}></button>
                </Link>
                <div className={`${styles.container}`}>
                    <span className={styles.cart__title}>Your Cart</span>
                    <div className={styles.cart__info}>
                        <span className={styles.cart__info_item}>product</span>
                        <span className={styles.cart__info_item}>Price</span>
                        <span className={styles.cart__info_item}>qty</span>
                        <span className={styles.cart__info_item}>Total</span>
                    </div>
                </div>
                {
                    items.map((item: any, ind: number) => {
                        return <CartItem styles={styles} image={item.image} price={item.price}
                            title={item.title}
                            key={item.variant_id}
                            quantity={item.quantity}
                            totalPrice={item.final_line_price}
                            ind={ind}
                        />
                    })
                }
                <div className={styles.container}>
                    <div className={styles.subtotal}>
                        <span className={styles.subtotal__title}>subtotal</span>
                        <span className={styles.subtotal__price}>{totalPrice / 10}$</span>
                    </div >

                    <div className={styles.cart__buttons}>
                        <Link to="/">
                            <button className={`${styles.cart__button} ${styles.back__btn}`}>
                                <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0.46967 6.53033C0.176777 6.23744 0.176777 5.76256 0.46967 5.46967L5.24264 0.6967C5.53553 0.403807 6.01041 0.403807 6.3033 0.6967C6.59619 0.989593 6.59619 1.46447 6.3033 1.75736L2.06066 6L6.3033 10.2426C6.59619 10.5355 6.59619 11.0104 6.3033 11.3033C6.01041 11.5962 5.53553 11.5962 5.24264 11.3033L0.46967 6.53033ZM18 6.75L1 6.75L1 5.25L18 5.25L18 6.75Z" fill="white" />
                                </svg>
                                continue shopping
                            </button>
                        </Link>
                        <button className={`${styles.cart__button} ${styles.checkout_btn}`}><a href="https://geniuspipe.com/checkout">check out</a></button>
                    </div>

                    <div className={styles.offers}>
                        <p className={styles.offers__title}>People Also Bought These!</p>
                        <div className={styles.offers__items}>
                            <OfferItem styles={styles} />
                            <OfferItem styles={styles} />
                            <OfferItem styles={styles} />
                            <OfferItem styles={styles} />
                            <OfferItem styles={styles} />
                            <OfferItem styles={styles} />
                        </div>
                    </div>
                </div >
            </div >
        </div >

    )
}

export default Cart