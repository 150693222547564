export enum Links {
    Shop = `https://geniuspipe.com/collections/retail`,
    HowItWorks = 'https://geniuspipe.com/pages/how-it-works',
    Faq = 'https://geniuspipe.com/a/tools/faq',
    Karma = 'https://geniuspipe.com/pages/genius-one',
    WholesaleStore = 'https://wholesale.geniuspipe.com/',
    Locator = 'https://geniuspipe.com/pages/store-locator',
    Login = 'https://geniuspipe.com/account/login',
    // cart = 'https://geniuspipe.com/cart', //99 999
    Policy = 'https://geniuspipe.com/pages/privacy-policy',
    GeniusOne = 'https://geniuspipe.com/pages/genius-one',
    Contacts = 'https://geniuspipe.com/pages/contact-us',
    Legal = 'https://geniuspipe.com/pages/terms-and-conditions',
    Press = 'https://geniuspipe.com/a/tools/get-press',
    Reviews = 'https://geniuspipe.com/pages/reviews',
    Covid = 'https://geniuspipe.com/pages/cannabis-vs-virus',
    Tommy = 'https://www.youtube.com/watch?v=0375ChXoU08',
    BuyMini = 'https://geniuspipe.com/products/genius-mini',
    Cart = 'https://geniuspipe.com/cart'
}
