// @ts-nocheck

const initialState = {
    totalCount: 0,
    totalPrice: 0,
    products: [],
    filteredProducts: [],
    filterValues: [],
    checkout: {}
}

interface Action {
    type: string,
    payload: string | any
}

function shopReducer(state = initialState, action: Action) {
    switch (action.type) {



        case "setCheckout":
            return {
                ...state,
                checkout: action.payload
            }



        default:
            return state
    }
}

export default shopReducer