import React, { FC, useContext } from 'react';
import { SettingsPipe } from "../../models/settingsPipe";
import styles from "./CustomPipe.module.scss";
import { bottomColors, colors, insertImage, topColors } from "../../models/static-data/colors";
import { designPipes } from "../../models/static-data/design-pipes";
import {engraving, sliders1, miniPipe3, boredApe, blackInsert} from '../../assets/images';
import { AppContext } from "../AppContainer";
import { sliders } from "../../models/static-data/sliders";
import { kindPipe } from "../../models/settingsPipe";

import { Link as LinkButton } from "../common/link";
import { fixedPipeAC } from '../../redux/actions';
import { Link } from 'react-router-dom'

import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store';



export const CustomPipeExample: FC = () => {
    const {
        activeTypePipe,
        activeColorPipe,
        activeDesignPipe,
        activeSlider,
        activeSliderMini,
        isMobile,
        activeColorPipeMini
    } = useContext(AppContext);


    const dispatch = useDispatch()
    const customPipeValue1 = useSelector((state: RootState) => state.FixedCustomPipe.custopPipeValue1)
    const customPipeValue2 = useSelector((state: RootState) => state.FixedCustomPipe.custopPipeValue2)
    const activeTab = useSelector((state: RootState) => state.CustomPipeExample.activeTab)
    const countLines = useSelector((state: RootState) => state.FixedCustomPipe.countLines)
    //@ts-ignore
    const { colorName, products } = useSelector((state: RootState) => state.draftProduct)
    // @ts-ignore
    const { topColorIndex, bottomColorIndex, insertImageIndex } = useSelector((state: RootState) => state.customPipe)

    // console.log()

    const inputStyles = {
        maxWidth: '52vw',
        border: 'none',
        outline: 'none',
        textAlign: 'center',
        backgroundColor: 'transparent',
        textShadow: '0 1px 1px #b3b3b3',
        color: '#b3b3b3',
        caretColor: '#000',
        // fontFamily: fontFamily
    }

    const getImageCustomSrc = () => {
        if (colorName) {
            return products[colorName].imageSrc
        } else {
            return designPipes[activeTypePipe].find((pipe: any) => pipe.id === activeDesignPipe)?.pipe
        }
    }


    // const drawEngravingLine = () => {
    //     switch (countEngravingLine) {
    //         case 1:
    //             return <p>Have a nice day!</p>;
    //         case 2:
    //             return <>
    //                 <p style={{ marginBottom: '10px' }}>Have a nice day!</p>
    //                 <p>{customPipeValue1}</p>
    //             </>
    //         default:
    //             return null;
    //     }
    // };

    const onClickImage = () => {
        dispatch(fixedPipeAC(true))
        document.body.style.overflowY = 'hidden'
        localStorage.setItem('scrolled', JSON.stringify(window.pageYOffset))
        // imageToggleFixedPipe.current!.focus({ preventScroll: true })
        //@ts-ignore
    }

    const getImageSrc = () => {
        // colors[activeTypePipe].find((color: any) => color.id === activeColorPipe)?.pipe || miniPipe3
        if (activeTypePipe === kindPipe.Standard) {
            return colors[activeTypePipe].find((color: any) => color.id === activeColorPipe)?.pipe || blackInsert
        } else {
            return colors[activeTypePipe].find((color: any) => color.id === activeColorPipeMini)?.pipe || boredApe
        }
    }


    switch (activeTab) {
        // case SettingsPipe.Slider:
        //     return (
        //         // <div className={styles.colors} >
        //         //     {/*@ts-ignore*/}
        //         //     <img src={colors[activeTypePipe].find((color: any) => color.id === activeColorPipe)?.pipe || pipes1}
        //         //         alt="" />
        //         //     {activeSlider >= 0 &&
        //         //         <img
        //         //             src={sliders.find((slide: any) => slide.id === activeSlider)?.slider || sliders1}
        //         //             alt=""
        //         //         />
        //         //     }
        //         // </div>
        //         <div className={styles.design}>
        //             {/* <img src={getImageCustomSrc()}
        //                 alt=""
        //             /> */}
        //             {/* @ts-ignore */}
        //             <img className={styles.insertImage} src={insertImage.find((image: any) => insertImageIndex === image.imageName)?.image} alt="" />
        //             <img className={styles.topColor} src={topColors.find((color: any) => color.imageName === topColorIndex)?.image} alt="" />
        //             <img className={styles.bottomColor} src={bottomColors.find((color: any) => color.imageName === bottomColorIndex)?.image} alt="" />

        //             {activeSlider >= 0 &&
        //                 <img className={styles.sliderImage}
        //                     src={sliders.find((slide: any) => slide.id === activeSlider)?.slider || sliders1}
        //                     alt=""
        //                 />
        //             }
        //         </div >
        //     );



        case SettingsPipe.Personalize:
            return (
                <div>

                    <div className={styles.engraving}>
                        <div className={styles.text}>
                            {/* @ts-ignore */}
                            <input type="text" onChange={() => { }} value={customPipeValue1} placeholder='enter your custom title...' style={inputStyles} />
                            {/* @ts-ignore */}
                            {countLines > 1 && <input type="text" onChange={() => { }} value={customPipeValue2} placeholder='enter your custom title...' style={inputStyles} />}
                        </div>
                        <Link to="create-pipe-title">
                            <img src={engraving} alt="" onClick={onClickImage} />
                        </Link>

                    </div>
                    {
                        isMobile &&
                        <LinkButton
                            margin="65px"
                            className={styles.link}
                            text={'Choose your text for engraving'}
                            mode={'white'}
                            link={'https://geniuspipe.com/collections/genius-classic/products/genius-gadget'}
                        />
                    }
                </div>
                // <div className={styles.design}>
                //     {/* <img src={getImageCustomSrc()}
                //         alt=""
                //     /> */}
                //     <img className={styles.insertImage} src={insertImage.find((image: any) => insertImageIndex === image.imageName)?.image} alt="" />
                //     <img className={styles.topColor} src={topColors.find((color: any) => color.imageName === topColorIndex)?.image} alt="" />
                //     <img className={styles.bottomColor} src={bottomColors.find((color: any) => color.imageName === bottomColorIndex)?.image} alt="" />

                //     {activeSlider >= 0 &&
                //         <img className={styles.sliderImage}
                //             src={sliders.find((slide: any) => slide.id === activeSlider)?.slider || sliders1}
                //             alt=""
                //         />
                //     }
                // </div >
            )

        //     );
        // case SettingsPipe.Design:
        //     return isApplyImg ? (
        //         <div className={styles.img} style={{ position: 'relative', overflow: "hidden" }}>
        //             {/*@ts-ignore*/}
        //             <img src={img} className={styles.mask} alt="" style={{ backgroundImage: `url(${fileImg[0]?.preview})` }} />
        //             <img src={sliders1} alt="" />
        //         </div>
        //     ) : (
        //         <div className={styles.design}>
        //             {/* <img src={getImageCustomSrc()}
        //                 alt=""
        //             /> */}
        //             <img className={styles.insertImage} src={insertImage.find((image: any) => insertImageIndex === image.imageName)?.image} alt="" />
        //             <img className={styles.topColor} src={topColors.find((color: any) => color.imageName === topColorIndex)?.image} alt="" />
        //             <img className={styles.bottomColor} src={bottomColors.find((color: any) => color.imageName === bottomColorIndex)?.image} alt="" />

        //             {activeSlider >= 0 &&
        //                 <img className={styles.sliderImage}
        //                     src={sliders.find((slide: any) => slide.id === activeSlider)?.slider || sliders1}
        //                     alt=""
        //                 />
        //             }
        //         </div >
        //     )


        default:


            if (activeTypePipe === 'mini') {
                return (
                    <div className={`${styles.design} ${styles.mini}`}>
                        <img src={colors["mini"].find((color: any) => color.id === activeColorPipeMini)?.pipe
                            || designPipes.mini.find((color: any) => color.id === activeColorPipeMini)?.pipe} alt="" />
                        <img className={styles.sliderImage}
                             src={sliders.mini.find((slide: any) => slide.id === activeSliderMini)?.slider || sliders1}
                             alt=""
                        />
                    </div>
                )
            }

            console.log('insett image', insertImageIndex, insertImage[insertImageIndex])

            return (
                // <div className={styles.colors}>
                //     {/*@ts-ignore*/}
                //     <img src={getImageSrc()}
                //         alt="" />
                //     {/*@ts-ignore*/}
                //     <img
                //         src={colors[activeTypePipe].find((color: any) => color.id === activeColorPipe)?.slider || miniSlider1}
                //         alt="" />
                // </div>



                <div className={styles.design}>
                    {/* <img src={getImageCustomSrc()}
                        alt=""
                    /> */}
                    <img className={styles.insertImage} src={insertImage.find((image: any) => insertImageIndex === image.imageName)?.image} alt="" />

                    <img className={styles.topColor} src={topColors.find((color: any) => color.imageName === topColorIndex)?.image} alt="" />
                    <img className={styles.bottomColor} src={bottomColors.find((color: any) => color.imageName === bottomColorIndex)?.image} alt="" />

                    {activeSlider >= 0 &&
                        <img className={styles.sliderImage}
                            src={sliders.standard.find((slide: any) => slide.id === activeSlider)?.slider || sliders1}
                            alt=""
                        />
                    }
                </div>
            )
    }
};
