import { useContext, useEffect, useState } from 'react'
import { Navigation } from '../../models/navigation'
import { nav } from '../../models/static-data/static-data'
import styles from './Header.module.scss'
import { Logo } from '../common/logo'
import { HeaderScroll } from './header-scroll'
import { AppContext } from '../AppContainer'
import axios from 'axios'
import { setCartData } from '../../redux/actions'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../../redux/store'
import { Link } from 'react-router-dom'
import { newCart, like, people } from "../../assets/icons";
import { createCheckout, fetchCheckout } from '../../utils/shop-utils'

import searchIcon from '../../assets/icons/header/search.svg'

const leftNavigation = [
	{
		id: 1,
		icon: newCart,
		title: '',
		link: 'https://geniuspipe.com/cart',
		lambaCount: true

	}, {
		id: 2,
		icon: like,
		title: '',
		link: '',
	}, {
		id: 3,
		icon: people,
		title: '',
		link: '',
	},
];

export const Header = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [cartDataState, setCartDataState] = useState({})
	//@ts-ignore
	const { isHeaderWhite, isIPad, isActiveSearchModal, setIsActiveSearchModal } = useContext(AppContext)

	const dispatch = useDispatch()
	const cartData = useSelector(({ cart }: RootState) => cart)

	// @ts-ignore

	useEffect(() => {
		dispatch(setCartData(cartDataState))
	}, [cartDataState])

	useEffect(() => {
		function getCartData(data: any) {
			dispatch(
				setCartData({
					totalCount: data.item_count,
					totalPrice: data.total_price,
					items: data.items,
				})
			)
		}

		// fetchCheckout('Z2lkOi8vc2hvcGlmeS9DaGVja291dC8yYzI5NzEwOWMwNDg2Mzc1NzhkMDE1OTAxYzcyYTgxYT9rZXk9NTRhMWRlZTBiZjdmZDU0YjYzZTljZjhmZjJmM2Y3MTQ=')

		// @ts-ignore
		window.getCartData = getCartData

		function test(): void {
			let script = document.createElement('script')
			script.src = 'https://geniuspipe.com/cart.json?callback=getCartData'
			document.getElementsByTagName('head')[0].appendChild(script)
		}

		test()

		// axios.post("/cart/add.js", {
		// 	"id": 25972366537,
		// 	"quantity": 1
		// }, {
		// 	headers: {
		// 		"Content-Type": "application/json",
		// 		"Access-Control-Allow-Origin": "*"
		// 	}
		// }).then(res => console.log(res.data))

		axios.get("http://geniuspipe.com/cart.json").then(res => console.log('reess:', res))

		const handleScroll = () => {
			if (window.scrollY > window.innerHeight) {
				setIsOpen(true)
			} else {
				setIsOpen(false)
			}
		}

		if (!isIPad) {
			document.addEventListener('scroll', handleScroll)
		}

		if (isIPad) {
			setIsOpen(true)
		}

		return () => {
			if (!isIPad) {
				document.removeEventListener('scroll', handleScroll)
			}
		}
	}, [])

	return !isOpen ? (
		<header className={`${styles.header} ${isHeaderWhite ? styles.white : ''}`}>
			<div className={styles.inner}>
				<Logo width={93} height={35} />
				<nav className={styles.nav_wrap}>
					<ul className={styles.nav}>
						{nav.map(({ link, id, title, navLink }: Navigation) => (
							<li key={id}>
								{navLink ? (
									<Link to={navLink}>{title}</Link>
								) : (
									<a href={link} target="_blank" rel="noreferrer">
										{title}
									</a>
								)}
							</li>
						))}
					</ul>
				</nav>
				<ul className={styles.menu}>
					<li
						style={{ position: 'relative', cursor: 'pointer' }}
						onClick={() => setIsActiveSearchModal(!isActiveSearchModal)}
					>
						<span className={styles.menu_icon}>
							<img src={searchIcon} alt="search" />
						</span>
					</li>
					{leftNavigation.map(({ link, id, icon, title, lambaCount }) => (
						<li key={id} style={{ position: 'relative' }}>
							<a
								href={link}
								className={styles.link}
								target="_blank"
								rel="noreferrer"
							>
								<span className={styles.menu_icon}>
									<img src={icon} alt={title} />
								</span>
							</a>
							{lambaCount === true && cartData.totalCount > 0 && (
								<div className={styles.lambaCount}>{cartData.totalCount}</div>
							)}
						</li>
					))}
				</ul>
			</div>

		</header>
	) : (
		<HeaderScroll setIsOpen={setIsOpen} />
	)
}
/* 
export const Header = () => {
	const [isOpen, setIsOpen] = useState(false)
	const [cartDataState, setCartDataState] = useState({})
	const { isHeaderWhite, isIPad, isActiveSearchModal, setIsActiveSearchModal } =
		useContext(AppContext)

	const dispatch = useDispatch()
	const cartData = useSelector(({ cart }: RootState) => cart)

	// @ts-ignore

	useEffect(() => {
		dispatch(setCartData(cartDataState))
	}, [cartDataState])

	useEffect(() => {
		function getCartData(data: any) {
			dispatch(
				setCartData({
					totalCount: data.item_count,
					totalPrice: data.total_price,
					items: data.items,
				})
			)
		}

		// @ts-ignore
		window.getCartData = getCartData

		function test() {
			let script = document.createElement('script')
			script.src = 'https://geniuspipe.com/cart.json?callback=getCartData'
			document.getElementsByTagName('head')[0].appendChild(script)
		}

		test()

		const handleScroll = () => {
			if (window.scrollY > window.innerHeight) {
				setIsOpen(true)
			} else {
				setIsOpen(false)
			}
		}

		if (!isIPad) {
			document.addEventListener('scroll', handleScroll)
		}

		if (isIPad) {
			setIsOpen(true)
		}

		return () => {
			if (!isIPad) {
				document.removeEventListener('scroll', handleScroll)
			}
		}
	}, [])

	return !isOpen ? (
		<header className={`${styles.header} ${isHeaderWhite ? styles.white : ''}`}>
			<div className={styles.inner}>
				<Logo width={93} height={35} />
				<nav className={styles.nav_wrap}>
					<ul className={styles.nav}>
						{nav.map(({ link, id, title, navLink }: Navigation) => (
							<li key={id}>
								{navLink ? (
									<Link to={navLink}>{title}</Link>
								) : (
									<a href={link} target="_blank" rel="noreferrer">
										{title}
									</a>
								)}
							</li>
						))}
					</ul>
				</nav>
			<ul className={styles.menu}>
				<li
					style={{ position: 'relative', cursor: 'pointer' }}
					onClick={() => setIsActiveSearchModal(!isActiveSearchModal)}
				>
					<img src={searchIcon} alt="search" />
				</li>

				{leftNavigation.map(({ link, id, icon, title, lambaCount }) => (
					<li key={id} style={{ position: 'relative' }}>
						<a
							href={link}
							className={styles.link}
							target="_blank"
							rel="noreferrer"
						>
							<img src={icon} alt={title} />
							{title}
						</a>
						{lambaCount === true && cartData.totalCount > 0 && (
							<div className={styles.lambaCount}>{cartData.totalCount}</div>
						)}
					</li>
				))}
			</ul>
			</div>

<<<<<<< HEAD
		if (!isIPad) {
			document.addEventListener('scroll', handleScroll);
		}

		if (isIPad) {
			setIsOpen(true);
		}

		return () => {
			if (!isIPad) {
				document.removeEventListener('scroll', handleScroll);
			}
		}
	}, []);

	const setCartModal = () => {
		dispatch(setVisibleCart(true))
	}

	return !isOpen
		? (
			<header
				className={`${styles.header} ${isHeaderWhite ? styles.white : ''}`}
			>
				<div className={styles.right}>
					<Logo width={93} height={35} />
					<nav>
						<ul className={styles.nav}>
							{
								nav.map(({ link, id, title, navLink, onClick }: Navigation) => (
									<li key={id} >
										<a
											href={link}
											target="_blank"
											rel="noreferrer"
										>
											{title}
										</a>

									</li>
								))
							}
						</ul>
					</nav>
				</div>
				<ul className={styles.menu}>
					{
						leftNavigation.map(({ link, id, icon, title, lambaCount }) => (
							<li key={id} style={{ position: 'relative' }}>
								<a
									href={link}
									className={styles.link}
									target="_blank"
									rel="noreferrer"
								>
									<img src={icon} alt={title} />
									{title}
								</a>
								{lambaCount === true && totalCount > 0 && <div className={styles.lambaCount}>{totalCount}</div>}
							</li>
						))
					}
				</ul>
			</header>
		) : (
			<HeaderScroll setIsOpen={setIsOpen} />
		)
};
*/
