import {
    gold,
    silver,
    black,
    //
    serpentAndSkulls,
    roseSelavy,
    theGuardian,
    steampunkAbe,
    useMeLikeADrug,
    rainbowWave,
    hypnoticGenius,
    legionOfSkanks,
    nightOverExon,
    monaLisa,
    frivolousGenius,
    frivolousGeniusBlue,
    highRollerMahogany,
    highRollerCherry,
    independenceDay,
    australia,
    madHatter,
    redQueen,
    passion,
    passionSunset,
    samsara,
    throughTheUniverse,
    blueberry,
    herb,
    mexico,
    stuntLifestyle,
    slimStuntaSnake,
    //
    redInsert,
    greenInsert,
    // blue,
    tealInsert,
    purpleInsert, goldInsert, silverInsert, blackInsert,

    boredApe, jungleBirds, mahogany

} from "../../assets/images";
import { DesignPipe } from "../settingsPipe";

const BUY_URL = 'https://geniuspipe.com/cart/add?id='

export const designPipes = {
    standard: [{
        id: DesignPipe.Gold,
        image: `url(${goldInsert}) center center`,
        pipe: goldInsert,
        link: `${BUY_URL}000000`,
        imageName: 'gold',
    }, {
        id: DesignPipe.Silver,
        image: `url(${silverInsert}) center center`,
        pipe: silverInsert,
        link: `${BUY_URL}000000`,
        imageName: 'silver',
    }, {
        id: DesignPipe.Black,
        image: `url(${blackInsert}) center center`,
        pipe: blackInsert,
        link: `${BUY_URL}000000`,
        imageName: 'black',
    },
    {
        id: DesignPipe.SerpentAndSkulls,
        image: `url(${serpentAndSkulls}) center -35px / cover`,
        pipe: serpentAndSkulls,
        link: `${BUY_URL}000000`,
        imageName: 'serpentAndSkulls',
    },{
        id: DesignPipe.RoseSelavy,
        image: `url(${roseSelavy}) center -45px / cover`,
        pipe: roseSelavy,
        link: `${BUY_URL}000000`,
        imageName: 'roseSelavy'
    },{
        id: DesignPipe.TheGuardian,
        image: `url(${theGuardian}) center -70px / cover`,
        pipe: theGuardian,
        link: `${BUY_URL}000000`,
        imageName: 'theGuardian'
    },{
        id: DesignPipe.SteampunkAbe,
        image: `url(${steampunkAbe}) center -55px / cover`,
        pipe: steampunkAbe,
        link: `${BUY_URL}000000`,
        imageName: 'steampunkAbe'
    },{
        id: DesignPipe.UseMeLikeADrug,
        image: `url(${useMeLikeADrug}) center -75px / cover`,
        pipe: useMeLikeADrug,
        link: `${BUY_URL}000000`,
        imageName: 'useMeLikeADrug'
    },{
        id: DesignPipe.RainbowWave,
        image: `url(${rainbowWave}) center -75px / cover`,
        pipe: rainbowWave,
        link: `${BUY_URL}000000`,
        imageName: 'rainbowWave'
    },{
        id: DesignPipe.HypnoticGenius,
        image: `url(${hypnoticGenius}) center -75px / cover`,
        pipe: hypnoticGenius,
        link: `${BUY_URL}000000`,
        imageName: 'hypnoticGenius'
    },{
        id: DesignPipe.LegionOfSkanks,
        image: `url(${legionOfSkanks}) center -58px / cover`,
        pipe: legionOfSkanks,
        link: `${BUY_URL}000000`,
        imageName: 'legionOfSkanks'
    },{
        id: DesignPipe.NightOverExon,
        image: `url(${nightOverExon}) center -110px / cover`,
        pipe: nightOverExon,
        link: `${BUY_URL}000000`,
        imageName: 'nightOverExon'
    },{
        id: DesignPipe.MonaLisa,
        image: `url(${monaLisa}) center -50px / cover`,
        pipe: monaLisa,
        link: `${BUY_URL}000000`,
        imageName: 'monaLisa'
    },{
        id: DesignPipe.FrivolousGenius,
        image: `url(${frivolousGenius}) center -75px / cover`,
        pipe: frivolousGenius,
        link: `${BUY_URL}000000`,
        imageName: 'frivolousGenius'
    },{
        id: DesignPipe.FrivolousGeniusBlue,
        image: `url(${frivolousGeniusBlue}) center -75px / cover`,
        pipe: frivolousGeniusBlue,
        link: `${BUY_URL}000000`,
        imageName: 'frivolousGeniusBlue'
    },{
        id: DesignPipe.HighRollerMahogany,
        image: `url(${highRollerMahogany}) center -75px / cover`,
        pipe: highRollerMahogany,
        link: `${BUY_URL}000000`,
        imageName: 'highRollerMahogany'
    },{
        id: DesignPipe.HighRollerCherry,
        image: `url(${highRollerCherry}) center -75px / cover`,
        pipe: highRollerCherry,
        link: `${BUY_URL}000000`,
        imageName: 'highRollerCherry'
    },{
        id: DesignPipe.IndependenceDay,
        image: `url(${independenceDay}) center -85px / cover`,
        pipe: independenceDay,
        link: `${BUY_URL}000000`,
        imageName: 'independenceDay'
    },{
        id: DesignPipe.Australia,
        image: `url(${australia}) center -85px / cover`,
        pipe: australia,
        link: `${BUY_URL}000000`,
        imageName: 'australia'
    },{
        id: DesignPipe.MadHatter,
        image: `url(${madHatter}) center -95px / cover`,
        pipe: madHatter,
        link: `${BUY_URL}000000`,
        imageName: 'madHatter'
    },{
        id: DesignPipe.RedQueen,
        image: `url(${redQueen}) center -95px / cover`,
        pipe: redQueen,
        link: `${BUY_URL}000000`,
        imageName: 'redQueen'
    },{
        id: DesignPipe.Passion,
        image: `url(${passion}) center -45px / cover`,
        pipe: passion,
        link: `${BUY_URL}000000`,
        imageName: 'passion'
    },{
        id: DesignPipe.PassionSunset,
        image: `url(${passionSunset}) center -45px / cover`,
        pipe: passionSunset,
        link: `${BUY_URL}000000`,
        imageName: 'passionSunset'
    },{
        id: DesignPipe.Samsara,
        image: `url(${samsara}) center -67px / cover`,
        pipe: samsara,
        link: `${BUY_URL}000000`,
        imageName: 'samsara'
    },{
        id: DesignPipe.ThroughTheUniverse,
        image: `url(${throughTheUniverse}) center -70px / cover`,
        pipe: throughTheUniverse,
        link: `${BUY_URL}000000`,
        imageName: 'throughTheUniverse'
    },{
        id: DesignPipe.Blueberry,
        image: `url(${blueberry}) center -70px / cover`,
        pipe: blueberry,
        link: `${BUY_URL}000000`,
        imageName: 'blueberry'
    },{
        id: DesignPipe.Herb,
        image: `url(${herb}) center -62px / cover`,
        pipe: herb,
        link: `${BUY_URL}000000`,
        imageName: 'herb'
    },{
        id: DesignPipe.Mexico,
        image: `url(${mexico}) center -65px / cover`,
        pipe: mexico,
        link: `${BUY_URL}000000`,
        imageName: 'mexico'
    },{
        id: DesignPipe.StuntLifestyle,
        image: `url(${stuntLifestyle}) center -65px / cover`,
        pipe: stuntLifestyle,
        link: `${BUY_URL}000000`,
        imageName: 'stuntLifestyle'
    },{
        id: DesignPipe.SlimStuntaSnake,
        image: `url(${slimStuntaSnake}) center -65px / cover`,
        pipe: slimStuntaSnake,
        link: `${BUY_URL}000000`,
        imageName: 'slimStuntaSnake'
    },
    {
        id: DesignPipe.Red,
        image: `url(${redInsert}) center center`,
        pipe: redInsert,
        link: `${BUY_URL}000000`,
        imageName: 'red'
    }, {
        id: DesignPipe.Green,
        image: `url(${greenInsert}) center center`,
        pipe: greenInsert,
        link: `${BUY_URL}000000`,
        imageName: 'green'
    }, {
        id: DesignPipe.Teal,
        image: `url(${tealInsert}) center center`,
        pipe: tealInsert,
        link: `${BUY_URL}000000`,
        imageName: 'teal'
    }, {
        id: DesignPipe.Purple,
        image: `url(${purpleInsert}) center center`,
        pipe: purpleInsert,
        link: `${BUY_URL}000000`,
        imageName: 'purple'
    }],
    mini: [{
        id: DesignPipe.BoredApe,
        image: `url(${boredApe})`,
        color: `url(${boredApe}) -45px -55px / 220%`,
        name: '',
        nameColor: 'boredApe',
        pipe: boredApe,
        link: `${BUY_URL}000000`,
        imageName: boredApe
    }, {
        id: DesignPipe.JungleBirds,
        image: `url(${jungleBirds})`,
        color: `url(${jungleBirds}) -45px -45px / 220%`,
        name: '',
        nameColor: 'jungleBirds',
        pipe: jungleBirds,
        link: `${BUY_URL}000000`,
        imageName: 'jungleBirds'
    }, {
        id: DesignPipe.Mahogany,
        image: `url(${mahogany})`,
        color: `url(${mahogany}) -45px -45px / 220%`,
        name: '',
        nameColor: 'mahogany',
        pipe: mahogany,
        link: `${BUY_URL}000000`,
        imageName: 'mahogany'
    }]
};
