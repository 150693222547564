
const initialState = {
    insertColor: "",
    image: "",
    slider: "",
    colorName: "",
    activeItem: null
}

function draftReducer(state = initialState, action: any) {
    switch (action.type) {
        case "setDraftProductData":

            return {
                ...state,
                ...action.payload
            }
        case "setDraftActiveItem":

            return {
                ...state,
                activeItem: action.payload
            }
        default:
            return state
    }
}

export default draftReducer