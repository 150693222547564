import React, { useContext, useEffect } from 'react';
import styles from './CustomPipeColor.module.scss';
import { AppContext } from "../../AppContainer";
import { kindPipe } from "../../../models/settingsPipe";
import { CustomPipeColorBlock } from "./custom-pipe-color-block";
import {Slider} from "../../common/slider";
import {CustomPipeSlider} from "../custom-pipe-slider";

export const CustomPipeColor = () => {
    const { activeTypePipe, setActiveTypePipe } = useContext(AppContext);

    useEffect(() => {
        return () => {
            setActiveTypePipe(kindPipe.Standard);
        }
    }, []);

    return (
        <div className={styles.color}>
            <div className={styles.tabs}>
                <button
                    className={`${styles.tab} ${activeTypePipe === kindPipe.Standard ? styles.active : ''}`}
                    onClick={() => setActiveTypePipe(kindPipe.Standard)}
                >
                    Standard
                </button>
                <button
                    className={`${styles.tab} ${activeTypePipe === kindPipe.Mini ? styles.active : ''}`}
                    onClick={() => setActiveTypePipe(kindPipe.Mini)}
                >
                    Mini
                </button>
            </div>
            <CustomPipeColorBlock />
            <div className={activeTypePipe !== kindPipe.Standard ? styles.hidden : ''}>
                <Slider />
            </div>
            <div className={activeTypePipe !== kindPipe.Mini ? styles.hidden : ''}>
                <CustomPipeColorBlock type={'mini'} />
            </div>
            <CustomPipeColorBlock type={'slider'} />
            {/*<CustomPipeSlider />*/}
        </div>
    )
};
