// @ts-nocheck
import styles from './Circle.module.scss';
import React, { FC } from 'react';
import { CircleProps } from "../../../models/CircleProps";

export const Circle: FC<CircleProps> = ({
    isActive,
    name,
    onClick,
    background,
    id,
    marginLeft
}) => {
    return (
        <div
            style={{ marginLeft }}
            className={`${styles.circle} ${isActive ? styles.active : ''}`}
            onClick={() => { onClick(id) }}
        >
            <div className={styles.wrapper}>
                <button
                    className={styles.color}
                    style={{
                        background: `${background}`
                    }}
                />
            </div>
            {name && <p>{name}</p>}
        </div>
    )
};
