import {DesignPipe} from "../../models/settingsPipe";

const initialState = {
    existProducts: [],
    topColorIndex: "silver",
    bottomColorIndex: "silver",
    insertImageIndex: "black",
    sliderIndex: "silver"
}

function customPipe(state = initialState, action: any) {
    switch (action.type) {
        case "setCustomPipeData":
            return {
                ...state,
                ...action.payload
            }
        case "setExistPipes":
            return {
                ...state,
                ...action.payload
            }
        default:
            return state
    }
}

export default customPipe
